import { SwipeableDrawer } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { IMG_URL, dataService } from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { Formik } from "formik";
import { addProductSchema } from "../../../../validation/validation";
import { toast } from "react-toastify";

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "600px",
  overflow: "auto"
};

export default function UserList() {
  const [initialValues, setInitialValues] = useState({
    categoryId: "",
    ingredients: "",
    subCategoryId: "",
    name: "",
    price: "",
    hsnCode: "",
    availableQty: "",
    contains: "",
    useFor: ""
  });
  const [values, setValues] = useState({});
  const [categoryListData, setCategoryListData] = useState([]);
  const [subCategoryListData, setSubCategoryListData] = useState([]);
  const [productListData, setProductListData] = useState([]);
  const [deleteDataId, setDeleteDataId] = useState("")

  // add user modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // delete modal
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  // filter
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const submitForm = (data) => {
    const formData = new FormData();
    for (let key in data) {
      if (Array.isArray(data[key])) {
        for (let k in data[key]) {
          formData.append(key, data[key][k])
        }
      } else {
        formData.append(key, data[key])
      }
    }
    if (initialValues?._id) {
      formData.append('id', initialValues?._id)
    }
    dataService
      .post(Api.AddProduct, formData)
      .then((e) => {
        toast.success("Added");
        getAllProduct();
        handleClose();
        window.location.reload(true)
      })
      .catch((e) => {
      });
  };

  const deleteData = () => {
    dataService.delete(Api.DeleteProduct + "?id=" + deleteDataId).then(() => {
      toast.success("Delete");
      handleCloseDelete();
      getAllProduct();
    })
  }

  const getAllCategory = () => {
    dataService
      .get(Api.CategoryList)
      .then(({ data }) => {
        setCategoryListData(data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getAllProduct = () => {
    dataService
      .get(Api.GetProducts)
      .then(({ data }) => {
        setProductListData(data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getAllSubCategory = (id) => {
    dataService
      .get(Api.SubCategoryList + "?categoryId=" + id)
      .then(({ data }) => {
        setSubCategoryListData(data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getAllCategory();
    getAllProduct();
  }, []);

  useEffect(() => {
    if (values?.categoryId) {
      getAllSubCategory(values?.categoryId);
    }
  }, [values?.categoryId]);

  return (
    <>
      <Index.Box className="dashboard-content user-list-content">
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            Product List
          </Index.Typography>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                  />
                  <img
                    src={PagesIndex.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="filter-main">
              {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <Index.Box className="export-btn-main border-btn-main">
                    <Index.Button
                      className="export-btn border-btn"
                    // onClick={toggleDrawer(anchor, true)}
                    >
                      <img
                        src={PagesIndex.Svg.filter}
                        className="down-icon"
                        alt="download icon"
                      />
                      Filter
                    </Index.Button>
                  </Index.Box>
                  <SwipeableDrawer
                    className="filter-main"
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    <Index.Box className="filter-header">
                      <Index.Typography className="filter-title">
                        Filter
                      </Index.Typography>
                      <img
                        src={PagesIndex.Png.close}
                        className="filter-close-icon"
                        onClick={toggleDrawer(anchor, false)}
                      />
                    </Index.Box>
                    <Index.Box className="filter-inner-main">
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          Name
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder=""
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          City
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder=""
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          Status
                        </Index.FormHelperText>
                        <Index.Box className="checkbox-main filter-checkbox-main input-box">
                          <Index.FormControlLabel
                            control={<Index.Checkbox defaultChecked />}
                            label="Active"
                            className="checkbox-lable"
                          />
                          <Index.FormControlLabel
                            control={<Index.Checkbox />}
                            label="Deactivate"
                            className="checkbox-lable"
                          />
                          <Index.FormControlLabel
                            control={<Index.Checkbox />}
                            label="Pending"
                            className="checkbox-lable"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="filter-footer">
                      <Index.Box className="filter-btn-main border-btn-main btn-main-primary">
                        <Index.Button className="border-btn filter-cancel-btn">
                          Cancel
                        </Index.Button>
                        <Index.Button className="btn-primary filter-btn">
                          Filter
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </Index.Box>
            <Index.Box className="userlist-inner-btn-flex">
              <Index.Box className="export-btn-main border-btn-main">
                <Index.Button
                  className="export-btn border-btn"
                // onClick={handleOpenDelete}
                >
                  <img
                    src={PagesIndex.Svg.down}
                    className="down-icon"
                    alt="download icon"
                  />
                  Export
                </Index.Button>
              </Index.Box>
              <Index.Box className="adduser-btn-main btn-main-primary">
                <Index.Button
                  className="adduser-btn btn-primary"
                  onClick={handleOpen}
                >
                  <img
                    src={PagesIndex.Svg.plus}
                    className="plus-icon"
                    alt="plus icon"
                  />
                  Add
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="userlist-table-main page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table aria-label="simple table" className="table">
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Product Category
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Product Sub Category
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Name
                            </Index.TableCell>
                            {/* <Index.TableCell component='th' variant='th' className='table-th'>CITY</Index.TableCell>
                                                                                    <Index.TableCell component='th' variant='th' className='table-th'>NUMBER</Index.TableCell> */}
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              STATUS
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              ACTION
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {productListData?.map((row) => (
                            <Index.TableRow
                              key={row._id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {row?.categoryId?.categoryName}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row?.subCategoryId?.subCategoryName}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.name}
                              </Index.TableCell>
                              {/* <Index.TableCell component='td' variant='td' className='table-td'>{row.city}</Index.TableCell>
                                                                                          <Index.TableCell component='td' variant='td' className='table-td'>{row.number}</Index.TableCell> */}
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.isActive ? "Active" : "In-Active"}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                <Index.Box className="userdata-btn-flex">
                                  <Index.Button
                                    onClick={() => {
                                      setInitialValues({ ...row, categoryId: row?.categoryId?._id, subCategoryId: row?.subCategoryId?._id });
                                      //   initialValues=row;
                                      handleOpen();
                                      // 
                                    }}
                                  >
                                    <img src={PagesIndex.Svg.blueedit}></img>
                                  </Index.Button>
                                  <Index.Button
                                    onClick={() => {
                                      setDeleteDataId(row._id)
                                      handleOpenDelete()
                                    }}
                                  >
                                    <img src={PagesIndex.Svg.trash}></img>
                                  </Index.Button>
                                  <Index.Button
                                    onClick={() => {
                                      // 
                                      handleOpen();
                                    }}
                                  >
                                    <img src={PagesIndex.Svg.yelloweye}></img>
                                  </Index.Button>
                                </Index.Box>
                              </Index.TableCell>
                            </Index.TableRow>
                          ))}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                  <Index.Box className="pagination-main">
                    <Index.Pagination
                      count={3}
                      variant="outlined"
                      shape="rounded"
                      className="pagination"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-ingredients"
        className="modal"
      >
        <Index.Box sx={style} className="add-user-modal-inner-main modal-inner">
          <Formik
            enableReinitialize
            innerRef={(formikActions) =>
              formikActions ? setValues(formikActions.values) : setValues({})
            }
            validationSchema={addProductSchema}
            initialValues={initialValues}
            onSubmit={submitForm}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              isValid,
              dirty,
              touched,
              handleBlur,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                {console.log(values.ingredients, "maulik595")}
                <Index.Box className="modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Add
                  </Index.Typography>
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="modal-close-icon"
                    onClick={handleClose}
                  />
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Select Category
                  </Index.FormHelperText>
                  <Index.Box className="dropdown-box">
                    <Index.FormControl className="form-control drop-form-control">
                      <Index.Select
                        className="dropdown-select drop-select"
                        value={values.categoryId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="categoryId"
                        helperText={touched.categoryId && errors.categoryId}
                        error={Boolean(errors.categoryId && touched.categoryId)}
                      >
                        <Index.MenuItem value="" className="drop-menuitem">
                          Select category
                        </Index.MenuItem>
                        {categoryListData?.map((e) => {
                          return (
                            <Index.MenuItem
                              value={e._id}
                              className="drop-menuitem"
                            >
                              {e.categoryName}
                            </Index.MenuItem>
                          );
                        })}
                      </Index.Select>
                      {/* <span><img src={Index.Svg.instagram} className="grey-down pay-down" alt='grey down arrow'></img></span> */}
                    </Index.FormControl>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Select Sub Category
                  </Index.FormHelperText>
                  <Index.Box className="dropdown-box">
                    <Index.FormControl className="form-control drop-form-control">
                      <Index.Select
                        className="dropdown-select drop-select"
                        value={values.subCategoryId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="subCategoryId"
                        helperText={
                          touched.subCategoryId && errors.subCategoryId
                        }
                        error={Boolean(
                          errors.subCategoryId && touched.subCategoryId
                        )}
                      >
                        <Index.MenuItem value="" className="drop-menuitem">
                          Select Sub category
                        </Index.MenuItem>
                        {subCategoryListData?.map((e) => {
                          return (
                            <Index.MenuItem
                              value={e._id}
                              className="drop-menuitem"
                            >
                              {e.subCategoryName}
                            </Index.MenuItem>
                          );
                        })}
                      </Index.Select>
                      {/* <span><img src={Index.Svg.instagram} className="grey-down pay-down" alt='grey down arrow'></img></span> */}
                    </Index.FormControl>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Product Name
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                      name="name"
                      helperText={touched.name && errors.name}
                      error={Boolean(errors.name && touched.name)}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Orignal Price
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      value={values.orignalPrice}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                      name="orignalPrice"
                      helperText={touched.orignalPrice && errors.orignalPrice}
                      error={Boolean(errors.orignalPrice && touched.orignalPrice)}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Selling Price
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      value={values.price}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                      name="price"
                      helperText={touched.price && errors.price}
                      error={Boolean(errors.price && touched.price)}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Available Qty
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      value={values.availableQty}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                      name="availableQty"
                      helperText={touched.availableQty && errors.availableQty}
                      error={Boolean(errors.availableQty && touched.availableQty)}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    HSN Code
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      value={values.hsnCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                      name="hsnCode"
                      helperText={touched.hsnCode && errors.hsnCode}
                      error={Boolean(errors.hsnCode && touched.hsnCode)}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Use For
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      value={values.useFor}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                      multiline
                      rows={5}
                      maxRows={10}
                      variant="outlined"
                      name="useFor"
                      helperText={touched.useFor && errors.useFor}
                      error={Boolean(errors.useFor && touched.useFor)}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Contains
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      value={values.contains}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                      multiline
                      rows={5}
                      maxRows={10}
                      variant="outlined"
                      name="contains"
                      helperText={touched.contains && errors.contains}
                      error={Boolean(errors.contains && touched.contains)}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Ingredients
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      value={values.ingredients}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                      multiline
                      rows={5}
                      maxRows={10}
                      variant="outlined"
                      name="ingredients"
                      helperText={touched.ingredients && errors.ingredients}
                      error={Boolean(errors.ingredients && touched.ingredients)}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Main Image
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <img src={values?.productMainImg ? URL.createObjectURL(values?.productMainImg) : values.mainImage ? IMG_URL + values.mainImage : PagesIndex.Png.imgPre} height={"100px"} width={"100px"} />
                    <Index.Button
                      fullWidth
                      variant="contained"
                      component="label"
                      type="button"
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                    >
                      Upload
                      <input
                        type="file"
                        hidden
                        id="productMainImg" name="productMainImg"
                        onChange={(event) => {
                          setFieldValue("productMainImg", event.currentTarget.files[0]);
                          event.target.files = null;
                          event.target.value = null;
                        }}
                      />
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Top Image
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <img src={values?.productTopImage ? URL.createObjectURL(values?.productTopImage) : values.topImage ? IMG_URL + values.topImage : PagesIndex.Png.imgPre} height={"100px"} width={"100px"} />
                    <Index.Button
                      fullWidth
                      variant="contained"
                      component="label"
                      type="button"
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                    >
                      Upload
                      <input
                        type="file"
                        hidden
                        id="productTopImage" name="productTopImage"
                        onChange={(event) => {
                          setFieldValue("productTopImage", event.currentTarget.files[0]);
                          event.target.files = null;
                          event.target.value = null;
                        }}
                      />
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Other Images
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.Grid container>
                      {
                        values?.otherImage?.map((e, index) => {
                          return (
                            <>

                              <Index.Grid item xs={4}>
                                <img src={IMG_URL + e.file} height={"100px"} width={"100px"} />
                                <Index.Button onClick={() => {
                                  setFieldValue("otherImage", [...values?.otherImage].filter((_, i) => i !== index));
                                  setFieldValue("otherImageRemoved", values?.otherImageRemoved ? [...values?.otherImageRemoved, e._id] : [e._id]);
                                  setFieldValue("otherImageRemovedName", values?.otherImageRemovedName ? [...values?.otherImageRemovedName, e.file] : [e.file]);
                                }}>Remove</Index.Button>
                              </Index.Grid>
                            </>
                          )
                        })
                      }
                      {
                        values?.productOtherImage?.map((e, index) => {
                          return (
                            <>

                              <Index.Grid item xs={4}>
                                <img src={URL.createObjectURL(e)} height={"100px"} width={"100px"} />
                                <Index.Button onClick={() => {
                                  setFieldValue("productOtherImage", [...values?.productOtherImage].filter((_, i) => i !== index));
                                }}>Remove</Index.Button>
                              </Index.Grid>
                            </>
                          )
                        })
                      }
                    </Index.Grid>
                    {/* <img src={values?.productotherImage ? URL.createObjectURL(values?.productotherImage) : values.image ? IMG_URL + values.image : PagesIndex.Png.imgPre} height={"100px"} width={"100px"} /> */}
                    <Index.Button
                      fullWidth
                      variant="contained"
                      component="label"
                      type="button"
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                    >
                      Upload
                      <input
                        type="file"
                        multiple
                        hidden
                        id="productOtherImage" name="productOtherImage"
                        onChange={(event) => {
                          setFieldValue("productOtherImage", values?.productOtherImage ? [...values?.productOtherImage, ...event.currentTarget.files] : [...event.currentTarget.files]);
                          event.target.files = null;
                          event.target.value = null;
                        }}
                      />
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Banner Images
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.Grid container>
                      {
                        values?.bannerImage?.map((e, index) => {
                          return (
                            <>

                              <Index.Grid item xs={4}>
                                <img src={IMG_URL + e.file} height={"100px"} width={"100px"} />
                                <Index.Button onClick={() => {
                                  setFieldValue("bannerImage", [...values?.bannerImage].filter((_, i) => i !== index));
                                  setFieldValue("bannerImageRemoved", values?.bannerImageRemoved ? [...values?.bannerImageRemoved, e._id] : [e._id]);
                                  setFieldValue("bannerImageRemovedName", values?.bannerImageRemovedName ? [...values?.bannerImageRemovedName, e.file] : [e.file]);
                                }}>Remove</Index.Button>
                              </Index.Grid>
                            </>
                          )
                        })
                      }
                      {
                        values?.productBannerImage?.map((e, index) => {
                          return (
                            <>

                              <Index.Grid item xs={4}>
                                <img src={URL.createObjectURL(e)} height={"100px"} width={"100px"} />
                                <Index.Button onClick={() => {
                                  setFieldValue("productBannerImage", [...values?.productBannerImage].filter((_, i) => i !== index));
                                }}>Remove</Index.Button>
                              </Index.Grid>
                            </>
                          )
                        })
                      }
                    </Index.Grid>
                    {/* <img src={values?.productotherImage ? URL.createObjectURL(values?.productotherImage) : values.image ? IMG_URL + values.image : PagesIndex.Png.imgPre} height={"100px"} width={"100px"} /> */}
                    <Index.Button
                      fullWidth
                      variant="contained"
                      component="label"
                      type="button"
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                    >
                      Upload
                      <input
                        type="file"
                        multiple
                        hidden
                        id="productBannerImage" name="productBannerImage"
                        onChange={(event) => {
                          setFieldValue("productBannerImage", values?.productBannerImage ? [...values?.productBannerImage, ...event.currentTarget.files] : [...event.currentTarget.files]);
                          event.target.files = null;
                          event.target.value = null;
                        }}
                      />
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-user-btn-flex">
                  <Index.Box className="discard-btn-main border-btn-main">
                    <Index.Button className="discard-user-btn border-btn">
                      Discard
                    </Index.Button>
                  </Index.Box>
                  <Index.Box className="save-btn-main border-btn-main">
                    <Index.Button
                      type="submit"
                      className="save-user-btn border-btn"
                    >
                      <img
                        src={PagesIndex.Svg.save}
                        className="user-save-icon"
                      ></img>
                      Save
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Modal >

      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-ingredients"
        className="modal-delete modal"
      >
        <Index.Box sx={style} className="delete-modal-inner-main modal-inner">
          <Index.Box className="modal-circle-main">
            <img src={PagesIndex.Svg.closecircle} className="user-circle-img" />
          </Index.Box>
          <Index.Typography
            className="delete-modal-title"
            component="h2"
            variant="h2"
          >
            Are you sure?
          </Index.Typography>
          <Index.Typography
            className="delete-modal-para common-para"
            component="p"
            variant="p"
          >
            Do you really want to delete these records? This process cannot be
            undone.
          </Index.Typography>
          <Index.Box className="delete-modal-btn-flex">
            <Index.Button
              className="modal-cancel-btn modal-btn"
              onClick={handleCloseDelete}
            >
              Cancel
            </Index.Button>
            <Index.Button onClick={() => deleteData()} className="modal-delete-btn modal-btn">
              Delete
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}
