import usericon from './images/png/user.png';
import logo from './images/png/logo.png';
import edit from './images/png/edit.png';
import userlist1 from './images/png/userlist1.png';
import bergurmenu from './images/png/bergur-menu.png';
import close from './images/png/close.png';
import user1 from './images/png/user-1.png';
import imgPre from "./images/png/image-preview.png"






const Png = {
      usericon,
      logo,
      edit,
      userlist1,
      bergurmenu,
      close,
      user1,
      imgPre
}

export default Png;