const Api = {
    Login: "admin/login",
    CategoryList: "admin/get-product-category",
    AddCategory: "admin/add-product-category",
    DeleteCategory: "admin/delete-product-category",
    SubCategoryList: "admin/get-sub-product-category",
    AddSubCategory: "admin/add-sub-product-category",
    DeleteSubCategory: "admin/delete-product-sub-category",
    GetProducts: "admin/get-product",
    AddProduct: "admin/add-product",
    DeleteProduct: "admin/delete-product",
    GetSlider: "admin/get-slider",
    AddSlider: "admin/add-slider",
    DeleteSlider: "admin/delete-slider",
    GetBanner: "admin/get-banner",
    AddBanner: "admin/add-banner",
    DeleteBanner: "admin/delete-banner",
    GetAllCodes: "admin/lis-all-offers",
    AddOffers: "admin/manage-discount",
    DeleteOffers: "admin/delete-offers",
    GetProductsCategoryWithSub: "admin/get-all-products-category-sub"
};
export { Api };
