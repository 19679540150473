import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoutes = () => {
    // Check token expiry
    const isValidToken = (accessToken) => {
        if (!accessToken) return false;

        return true;
    };

    return isValidToken(localStorage.getItem("token")) ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
