import React from 'react'
import PropTypes from 'prop-types';
import Index from '../../../Index';
import PagesIndex from '../../../PagesIndex';


// for tabs design

function TabPanel(props) {
      const { children, value, index, ...other } = props;

      return (
            <div
                  role="tabpanel"
                  hidden={value !== index}
                  id={`simple-tabpanel-${index}`}
                  aria-labelledby={`simple-tab-${index}`}
                  {...other}
            >
                  {value === index && (
                        <Index.Box sx={{ p: 3 }}>
                              <Index.Typography>{children}</Index.Typography>
                        </Index.Box>
                  )}
            </div>
      );
}

TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
};

function a11yProps(index) {
      return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
      };
}



export default function EditProfile() {


      const [age, setAge] = React.useState('');
      
      const handleChangedropdown = (event) => {
            setAge(event.target.value);
      };

      const [value, setValue] = React.useState(0);

      const handleChange = (event, newValue) => {
            setValue(newValue);
      };

      // for custom switch design

      const IOSSwitch = Index.styled((props) => (
            <Index.Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
            width: 34,
            height: 20,
            padding: 0,
            '& .MuiSwitch-switchBase': {
                  padding: 0,
                  margin: 3,
                  transitionDuration: '300ms',
                  '&.Mui-checked': {
                        transform: 'translateX(16px)',
                        color: '#fff',
                        '& + .MuiSwitch-track': {
                              backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#114A65',
                              opacity: 1,
                              border: 0,
                        },
                        '&.Mui-disabled + .MuiSwitch-track': {
                              opacity: 0.5,
                        },
                  },
                  '&.Mui-focusVisible .MuiSwitch-thumb': {
                        color: '#33cf4d',
                        border: '6px solid #fff',
                  },
                  '&.Mui-disabled .MuiSwitch-thumb': {
                        color:
                              theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[600],
                  },
                  '&.Mui-disabled + .MuiSwitch-track': {
                        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
                  },
            },
            '& .MuiSwitch-thumb': {
                  boxSizing: 'border-box',
                  width: 14,
                  height: 14,
                  boxShadow: "none",
            },
            '& .MuiSwitch-track': {
                  borderRadius: 26 / 2,
                  backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
                  opacity: 1,
                  transition: theme.transitions.create(['background-color'], {
                        duration: 500,
                  }),
            },
      }));



      return (
            <Index.Box className="dashboard-content edit-profile-containt">
                  <Index.Typography className='admin-page-title' component='h2' variant='h2'>Account Settings</Index.Typography>

                  <Index.Box className="tabs-main-box">
                        <Index.Box sx={{ width: '100%' }}>
                              <Index.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Index.Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='admin-tabs-main'>
                                          <Index.Tab label="Edit Profile" {...a11yProps(0)} className="admin-tab" />
                                          <Index.Tab label="Change Password" {...a11yProps(1)} className="admin-tab" />
                                    </Index.Tabs>
                              </Index.Box>
                              <TabPanel value={value} index={0} className="admin-tabpanel">
                                    <Index.Box className="tabpanel-main">
                                          <Index.Box className="page-border">
                                                <Index.Typography className='common-para edit-highlight-text' component='p' variant='p'>General</Index.Typography>
                                                <Index.Typography className='common-para edit-para-text' component='p' variant='p'>Basic info, like your name and address that will displayed in public</Index.Typography>
                                                <Index.Box className="edit-profile-flex">
                                                      <Index.Box className="file-upload-btn-main">
                                                            <img src={PagesIndex.Png.usericon} className="upload-profile-img" alt='upload img'></img>
                                                            <Index.Button variant="contained" component="label" className='file-upload-btn'>
                                                                  <img src={PagesIndex.Svg.edit} className="upload-icon-img" alt='upload img'></img>
                                                                  <input hidden accept="image/*" multiple type="file" />
                                                            </Index.Button>
                                                      </Index.Box>
                                                      <Index.Box className="switch-main">
                                                            <Index.FormControlLabel
                                                                  control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                                                                  label="Public Profile" className='switch-lable'
                                                            />
                                                      </Index.Box>
                                                </Index.Box>
                                                <Index.Box className="add-user-data-main">
                                                      <Index.Box sx={{ width: 1 }} className="grid-main">
                                                            <Index.Box display="grid" className="display-row" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}>
                                                                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                                                                        <Index.Box className="input-box add-user-input">
                                                                              <Index.FormHelperText className='form-lable'>Name</Index.FormHelperText>
                                                                              <Index.Box className="form-group">
                                                                                    <Index.TextField
                                                                                          fullWidth
                                                                                          id="fullWidth"
                                                                                          className="form-control"
                                                                                          placeholder=""
                                                                                    />
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                                                                        <Index.Box className="input-box add-user-input">
                                                                              <Index.FormHelperText className='form-lable'>Email</Index.FormHelperText>
                                                                              <Index.Box className="form-group">
                                                                                    <Index.TextField
                                                                                          fullWidth
                                                                                          id="fullWidth"
                                                                                          className="form-control"
                                                                                          placeholder=""
                                                                                    />
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                                                                        <Index.Box className="input-box add-user-input">
                                                                              <Index.FormHelperText className='form-lable'>Title</Index.FormHelperText>
                                                                              <Index.Box className="form-group">
                                                                                    <Index.TextField
                                                                                          fullWidth
                                                                                          id="fullWidth"
                                                                                          className="form-control"
                                                                                          placeholder=""
                                                                                    />
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                                                                        <Index.Box className="input-box add-user-input">
                                                                              <Index.FormHelperText className='form-lable'>Language</Index.FormHelperText>
                                                                              <Index.Box className='dropdown-box'>
                                                                                    <Index.FormControl className='form-control drop-form-control'>
                                                                                          <Index.Select className='dropdown-select drop-select'
                                                                                                value={age}
                                                                                                onChange={handleChangedropdown}
                                                                                                displayEmpty
                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                          >
                                                                                                <Index.MenuItem value="" className='drop-menuitem'>
                                                                                                English
                                                                                                </Index.MenuItem>
                                                                                                <Index.MenuItem value={10} className='drop-menuitem'>English</Index.MenuItem>
                                                                                                <Index.MenuItem value={20} className='drop-menuitem'>English</Index.MenuItem>
                                                                                                <Index.MenuItem value={30} className='drop-menuitem'>English</Index.MenuItem>
                                                                                          </Index.Select>
                                                                                          {/* <span><img src={Index.Svg.instagram} className="grey-down pay-down" alt='grey down arrow'></img></span> */}
                                                                                    </Index.FormControl>
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                  </Index.Box>




                                                                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }} className="grid-column">
                                                                        <Index.Box className="user-btn-flex">
                                                                              <Index.Box className="discard-btn-main border-btn-main">
                                                                                    <Index.Button className='discard-user-btn border-btn'>Discard</Index.Button>
                                                                              </Index.Box>
                                                                              <Index.Box className="save-btn-main border-btn-main">
                                                                                    <Index.Button className='save-user-btn border-btn'><img src={PagesIndex.Svg.save} className="user-save-icon"></img>Save</Index.Button>
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                    </Index.Box>
                              </TabPanel>
                              <TabPanel value={value} index={1} className="admin-tabpanel">
                                    <Index.Box className="tabpanel-main">
                                          <Index.Box className="change-pass-main">
                                                <Index.Typography className='common-para edit-highlight-text' component='p' variant='p'>Password</Index.Typography>
                                                <Index.Typography className='common-para edit-para-text' component='p' variant='p'>Enter your current & new password to reset your password</Index.Typography>
                                                <Index.Box className="input-box change-pass-input-box">
                                                      <Index.FormHelperText className='form-lable'>Old Password</Index.FormHelperText>
                                                      <Index.Box className="form-group">
                                                            <Index.TextField
                                                                  fullWidth
                                                                  id="fullWidth"
                                                                  className="form-control"
                                                                  placeholder=""
                                                            />
                                                      </Index.Box>
                                                </Index.Box>
                                                <Index.Box className="input-box change-pass-input-box">
                                                      <Index.FormHelperText className='form-lable'>New Password</Index.FormHelperText>
                                                      <Index.Box className="form-group">
                                                            <Index.TextField
                                                                  fullWidth
                                                                  id="fullWidth"
                                                                  className="form-control"
                                                                  placeholder=""
                                                            />
                                                      </Index.Box>
                                                </Index.Box>
                                                <Index.Box className="input-box change-pass-input-box">
                                                      <Index.FormHelperText className='form-lable'>Confirm Password</Index.FormHelperText>
                                                      <Index.Box className="form-group">
                                                            <Index.TextField
                                                                  fullWidth
                                                                  id="fullWidth"
                                                                  className="form-control"
                                                                  placeholder=""
                                                            />
                                                      </Index.Box>
                                                </Index.Box>
                                                <Index.Box className="user-btn-flex change-pass-flex">
                                                      <Index.Box className="discard-btn-main border-btn-main">
                                                            <Index.Button className='discard-user-btn border-btn'>Discard</Index.Button>
                                                      </Index.Box>
                                                      <Index.Box className="save-btn-main border-btn-main">
                                                            <Index.Button className='save-user-btn border-btn'><img src={PagesIndex.Svg.save} className="user-save-icon"></img>Save</Index.Button>
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                    </Index.Box>
                              </TabPanel>
                        </Index.Box>
                  </Index.Box>
            </Index.Box>
      )
}
