import './App.css';
import Routes from './routes/Routes'
import './assets/style/global.css'
import './container/admin/pages/categoryList/categoryList.css'
import './container/admin/pages/subCategory/subCategory.css'
import './container/admin/pages/layout/layout.css'
import './container/admin/pages/adduser/adduser.css'
import './container/admin/pages/editprofile/editprofile.css'
import './container/admin/pages/dashboard/dashboard.css'
import './container/admin/pages/usercard/usercard.css'
import './component/admin/auth/auth.css'
import './component/admin/defaulLayout/defaullayout.css'


import './assets/style/global.responsive.css';
import './component/admin/auth/auth.responsive.css'
import './component/admin/defaulLayout/defaullayout.responsive.css'
import './container/admin/pages/adduser/adduser.responsive.css'
import './container/admin/pages/categoryList/categoryList.responsive.css'
import './container/admin/pages/subCategory/subCategory.responsive.css'
import './container/admin/pages/usercard/usercard.responsive.css'
import './container/admin/pages/editprofile/editprofile.responsive.css'
import './container/admin/pages/dashboard/dashboard.responsive.css'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <div className="App">
      <Routes />
      <ToastContainer />
    </div>
  );
}

export default App;
