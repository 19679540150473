
import Svg from '../assets/Svg'
import Png from "../assets/Png";
import Sidebar from '../component/admin/defaulLayout/Sidebar';
import Header from '../component/admin/defaulLayout/Header';
import PaidLable from '../component/common/lables/PaidLable';
import FailedLable from '../component/common/lables/FailedLable';
import PendingLable from '../component/common/lables/PendingLable';
import PrimaryButton from '../component/common/Button/primarybutton/PrimaryButton';
import AuthBackground from '../component/admin/defaulLayout/AuthBackground';
import AuthFooter from '../component/admin/defaulLayout/AuthFooter';


export default {
      Svg,
      Png,
      Sidebar,
      Header,
      PaidLable,
      FailedLable,
      PendingLable,
      PrimaryButton,
      AuthFooter,
      AuthBackground,
};
