import React from "react";
import { styled } from '@mui/material/styles';
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { adminLoginSchema } from "../../../../validation/validation";
import { Formik } from "formik";
import { dataService } from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// for custom checkbox design


const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 0,
  border: "1px solid #114A65",
  width: 16,
  height: 16,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : 'transparent',
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: 'transparent',
  '&:before': {
    display: 'block',
    width: 12,
    height: 12,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: 'transparent',
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Index.Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}

export default function Login() {
  const navigate = useNavigate();
  const submitForm = (data) => {
    dataService.post(Api.Login, data).then(({ data }) => {
      localStorage.setItem("token", data.data)
      navigate("/admin");
    }).catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1"
      })
    })
  }

  return (
    <div>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main">
          <PagesIndex.AuthBackground />
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main">
              <Formik
                validationSchema={adminLoginSchema}
                initialValues={{
                  email: "",
                  password: "",
                }}
                onSubmit={submitForm}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleSubmit,
                  isValid,
                  dirty,
                  touched,
                  handleBlur,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Index.Box className="admin-login-inner">
                      <Index.Typography
                        component="h2"
                        variant="h2"
                        className="admin-wel-text"
                      >
                        Login!
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="admin-sign-para common-para"
                      >
                        Please enter your credentials to sign in!
                      </Index.Typography>
                      <Index.Box className="input-box">
                        <Index.FormHelperText className="form-lable">
                          Email
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            placeholder="Enter the email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // onFocus={handleFocus}
                            helperText={touched.email && errors.email}
                            error={Boolean(errors.email && touched.email)}
                            fullWidth
                            name="email"
                            id="fullWidth"
                            className="form-control"
                            type="text"
                            autocomplete="off"
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box">
                        <Index.FormHelperText className="form-lable">
                          Password
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            size="small"
                            name="password"
                            type="password"
                            placeholder="Enter the password"
                            className="form-control input-with-radius password-form-control"
                            variant="outlined"
                            autoComplete="off"
                            onBlur={handleBlur}
                            value={values.password}
                            onChange={handleChange}
                            helperText={touched.password && errors.password}
                            error={Boolean(
                              errors.password && touched.password
                            )}

                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="flex-all forgot-row">
                        <Index.Box className="checkbox-main">
                          {/* <BpCheckbox />
                          <Index.Typography className="checkbox-lable">Remember Me</Index.Typography> */}
                        </Index.Box>
                        <Index.Link className="text-decoration-none admin-forgot-para common-para">
                          Forgot Password?
                        </Index.Link>
                      </Index.Box>
                      <Index.Box className="btn-main-primary login-btn-main">
                        <Index.Button type="submit" className="btn-primary login-btn">
                          Sign In
                        </Index.Button>
                      </Index.Box>
                      {/* <Index.Box className="or-text-main">
                        <Index.Typography className="or-cocntent-text" component='p'>
                          OR
                        </Index.Typography>
                      </Index.Box>
                      <PagesIndex.AuthFooter />
                      <Index.Box className="condition-content">
                        <Index.Typography className="condition-text"> Don't have an account? <span className="condition-span"><Index.Link className="condition-span">Sign Up here</Index.Link></span></Index.Typography>
                      </Index.Box> */}
                    </Index.Box>
                  </form>
                )}
              </Formik>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
