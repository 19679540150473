import React from 'react'
import Index from '../../Index'


export default function AuthBackground() {
      return (
            <>
                  <Index.Box className="login-left-bg"></Index.Box>
            </>
      )
}
