import React from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as Routess,
} from "react-router-dom";
import { createBrowserHistory } from 'history'
// import { createBrowserHistory } from "history";

//#region user
import { useSelector } from "react-redux";
//#endregion

//#region Admin
import Login from '../component/admin/auth/login/Login'
import ForgotPassword from '../component/admin/auth/forgotpassword/ForgotPassword'
import Otp from '../component/admin/auth/otp/Otp'
import SignIn from '../component/admin/auth/signin/SignIn'

import EditProfile from "../container/admin/pages/editprofile/EditProfile";
import LayOut from "../container/admin/pages/layout/LayOut";
import CategoryList from "../container/admin/pages/categoryList/categoryList";
import SubCategoryList from "../container/admin/pages/subCategory/subCategory";
import ProductList from "../container/admin/pages/productList/productList";
import SliderMangement from "../container/admin/pages/sliderMangement/sliderMangement";
import BannerManagement from "../container/admin/pages/bannerManagement/bannerManagement";
import Home from '../container/admin/pages/dashboard/Dashboard';
import AddUser from "../container/admin/pages/adduser/AddUser";
import UserCard from "../container/admin/pages/usercard/UserCard";
import PrivateRoutes from "./UserPrivateRoutes";
import OfferManagement from "../container/admin/pages/offerManagement/offerManagement";
//#endregion

const history = createBrowserHistory();
export default function Routes() {
  return (
    <BrowserRouter history={history}>
      <Routess>
        <Route path="/" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="otp-verify" element={<Otp />} />
        {/* <Route path="sign-in" element={<SignIn />} /> */}
        <Route element={<PrivateRoutes />}>
          <Route path="/admin" element={<LayOut />}>
            <Route path="dashboard" element={<Home />} />
            <Route path="edit-profile" element={<EditProfile />} />
            <Route path="category-list" element={<CategoryList />} />
            <Route path="sub-category-list" element={< SubCategoryList />} />
            <Route path="product-list" element={< ProductList />} />
            <Route path="slider-mangement" element={< SliderMangement />} />
            <Route path="banner-mangement" element={< BannerManagement />} />
            <Route path="offer-mangement" element={< OfferManagement />} />
            {/* <Route path="user-card" element={<UserCard />} /> */}
            {/* <Route path="add-user" element={<AddUser />} /> */}
          </Route>
        </Route>
      </Routess>
    </BrowserRouter>
  );
}
