import * as Yup from "yup";

export const adminLoginSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter your email")
    // .matches(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/, "This is not a valid format")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),

  // .oneOf([Yup.ref("email"), ""], "Email must match"),

  password: Yup.string()
    .required("Please enter your password")
    .min(8, "Password must be at least 8 characters")
    .max(16, "Password must be at most 16 characters")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/, "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character."),
});

export const addCategorySchema = Yup.object().shape({
  categoryName: Yup.string()
    .required("Please enter your categoryName"),
  description: Yup.string()
    .required("Please enter your description"),
  sequence: Yup.string()
    .required("Please enter your sequence"),
});

export const addSubCategorySchema = Yup.object().shape({
  categoryId: Yup.string()
    .required("Please enter your categoryName"),
  description: Yup.string()
    .required("Please enter your description"),
  subCategoryName: Yup.string()
    .required("Please enter your subCategoryName")
});

export const addProductSchema = Yup.object().shape({
  categoryId: Yup.string()
    .required("Please enter your categoryName"),
  ingredients: Yup.string()
    .required("Please enter your ingredients"),
  contains: Yup.string()
    .required("Please enter your contains"),
  useFor: Yup.string()
    .required("Please enter your useFor"),
  subCategoryId: Yup.string()
    .required("Please enter your subCategoryName"),
  name: Yup.string()
    .required("Please enter your name"),
  price: Yup.string()
    .required("Please enter your price"),
  availableQty: Yup.string()
    .required("Please enter your availableQty"),
  hsnCode: Yup.string()
    .required("Please enter your hsnCode")
});

export const addSliderSchema = Yup.object().shape({
  sliderName: Yup.string()
    .required("Please enter your sliderName"),
  description: Yup.string()
    .required("Please enter your password")
});

export const addBannerSchema = Yup.object().shape({
  categoryId: Yup.string()
    .required("Please enter your categoryName"),
  description: Yup.string()
    .required("Please enter your description"),
  bannerName: Yup.string()
    .required("Please enter your bannerName")
});

export const addCouponSchema = Yup.object().shape({
  promoCode: Yup.string()
    .required("Please enter your promoCode")
});